import React, { useRef, useState } from "react";
import { observer } from "mobx-react";

import styled from "styled-components";

import { BackHistory, stringifyUrl } from "src/func/url";

import RecentSearchList from "./views/Recent";
import SearchResult from "./views/Result";
import ResultNone from "./views/ResultNone";

import { TMapStore } from "../../stores/TMapStore";
import {
  Back,
  Body1,
  Caption1,
  contents,
  foundation,
  zindex_fixed,
} from "ms-ui";
import { ISpot } from "src/interface/pages/Search/spot";
import { TMAP_POI } from "src/interface/pages/Search/tmap";
import { useURI } from "ms_router";
import { URI_FORM } from "src/interface/pages/Search/uri";
import { isNil } from "ms_data";

const SearchInput = () => {
  const { navigate, location, getQuery } = useURI();
  const form = getQuery<URI_FORM>();

  const searchInputRef = useRef<HTMLInputElement>(null);

  const [tmapStore] = useState(() => new TMapStore(navigate));

  const [isFocus, setFocus] = useState(false);

  React.useEffect(() => {
    const searchInput = searchInputRef.current;

    if (searchInput) {
      setTimeout(() => {
        searchInput.focus();
      }, 300);
    }

    return () => {
      searchInput && searchInput.blur();
    };
  }, []);

  const type = location.pathname.includes("start_form") ? "start" : "end";

  const id =
    type === "start"
      ? "search_resultStart"
      : type === "end"
      ? "search_resultEnd"
      : undefined;

  //검색 결과 맵으로 보기
  const goToMap = (spot: ISpot) => {
    const pathname = type === "start" ? "/search/start_map" : "/search/end_map";

    //기존 search
    let locationSearch = { ...form };

    //추가
    locationSearch[`${type}`] = spot;
    locationSearch.search = spot;

    //추가한것 얹어서 맵으로 푸시
    navigate({
      pathname,
      search: stringifyUrl(locationSearch),
    });
  };

  /**
   * 검색결과 선택 시
   * @param point
   */
  const clickResult = (point: TMAP_POI) => {
    // const type = location.pathname.includes("start_form") ? "start" : "end";

    const spotInfo: ISpot = {
      title: point.name,
      address: makeAddress(point),
      lat: Number(point.noorLat),
      lng: Number(point.noorLon),
      zipCode: point.zipCode,
    };

    // clickSearchResult(spotInfo, type);
    goToMap(spotInfo);
  };

  /**
   * 최근 검색어 선택 시
   * @param storage
   */
  const clickRecent = (storedSpotInfo: ISpot) => {
    console.log("storedSpotInfo: ", storedSpotInfo);
    // const type = location.pathname.includes("start_form") ? "start" : "end";
    // clickSearchLately(storedSpotInfo, type);

    goToMap(storedSpotInfo);
  };

  /**
   * tmap address 가공
   * @param c
   * @returns
   */
  const makeAddress = (point: TMAP_POI) => {
    const txtPrc = (spc: string, txt?: string) => {
      return !isNil(txt) ? spc + txt : "";
    };

    return (
      point.upperAddrName +
      " " +
      point.middleAddrName +
      txtPrc(" ", point.lowerAddrName) +
      txtPrc(" ", point.detailAddrName) +
      txtPrc(" ", point.firstNo) +
      txtPrc("-", point.secondNo)
    );
  };
  return (
    <>
      <StSearchNav>
        <StSearch>
          <StBackBtn onClick={() => BackHistory(navigate, "/")}>
            <Back color={"var(--contents-primary"} />
          </StBackBtn>
          <StInput
            ref={searchInputRef}
            type="text"
            autoFocus={false}
            value={tmapStore.text}
            onChange={(e) => {
              tmapStore.setText(e.currentTarget.value);
            }}
            onFocus={(e) => {
              setFocus(true);
            }}
            onBlur={(e) => {
              setFocus(false);
            }}
            placeholder={`${
              !isFocus
                ? `${type === "start" ? "출발지" : "도착지"}를 입력해주세요.`
                : "도로명, 건물명을 입력하세요."
            }`}
            autoComplete="off"
          />
        </StSearch>
      </StSearchNav>
      {/* <Divider height={8} /> */}
      <StContainer>
        <div style={{ position: "relative" }}>
          <StKeywordSearch>
            {tmapStore.text.length > 0 ? (
              <StResultSearchList>
                {tmapStore.response.poi.length === 0 ? (
                  tmapStore.loading ? null : (
                    <ResultNone />
                  )
                ) : (
                  <>
                    <StResultSearchListTitle>검색 결과</StResultSearchListTitle>
                    <StResultSearchListScroll>
                      {tmapStore.response.poi.map(
                        (item: TMAP_POI, i: number) => (
                          <SearchResult
                            onClick={clickResult}
                            spot={item}
                            key={i}
                            dataId={id && id}
                            dataIdx={id && i}
                            // gid={id && id + "_" + i}
                          />
                        )
                      )}
                    </StResultSearchListScroll>
                  </>
                )}
              </StResultSearchList>
            ) : (
              <RecentSearchList type={type} clickRecent={clickRecent} />
            )}
          </StKeywordSearch>
        </div>
      </StContainer>
    </>
  );
};

export default observer(SearchInput);

const StContainer = styled.div`
  margin-top: 64px;
  height: calc(100dvh - 64px);
  background-color: var(--contents-secondary);
`;

const StSearchNav = styled.div`
  width: 100%;
  max-width: 420px;
`;

const StBackBtn = styled.button`
  padding-left: 16px;
  padding-right: 16px;
  background-color: white;
  box-shadow: 0 -1px ${contents.tertiary.value} inset;
`;

const StSearch = styled.div`
  position: fixed;
  top: 0;
  height: var(--spacing-56);
  width: 100%;
  max-width: inherit;
  z-index: ${zindex_fixed};

  color: ${contents.subtitle.value};
  display: flex;
`;

const StKeywordSearch = styled.div`
  width: 100%;
  z-index: 1;
  background-color: var(--colors-gray-000);
`;

const StResultSearchList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 16px;
  color: var(--colors-gray-500);
`;

const StResultSearchListTitle = styled.div`
  ${Caption1}
  color: var(--colors-gray-400);
  display: flex;
  flex-direction: row;
  background-color: var(--colors-gray-000);
  border-bottom: solid 1px var(--colors-gray-090);
  padding: 16px;
`;

const StResultSearchListScroll = styled.div`
  height: calc(100dvh - 60px);
  overflow: auto;
`;

const StInput = styled.input`
  ${Body1}
  /* width: calc(100% - 48px); */
  width: 100%;
  background-color: white;
  caret-color: ${foundation.normal.secondary.value};
  height: 56px;
  padding: var(--spacing-16) 0 var(--spacing-16) 0;
  box-shadow: 0 -1px ${contents.tertiary.value} inset;

  &::placeholder {
    ${Body1}
    border-radius: 5px;
    color: ${contents.description.value};
  }
`;
